<script>
	import Vue from 'vue';
	import GlobalVue from '@helper/Global.vue';
	import moment from 'moment';
	import Gen from '@helper/Gen';
	import FOGen from '@helper/FOGen';
	import Croppa from 'vue-croppa';
	import swal from 'sweetalert';
	import VTooltip from 'v-tooltip'
	import {
		VueMasonryPlugin
	} from 'vue-masonry';

	/* Vue plugins */
	Vue.use(VueMasonryPlugin)
	Vue.use(Croppa);

	moment.locale("en")
	global.moment = moment

	/* CSS */
	import "@frontend/css/custom.scss"
	import "@frontend/css/responsive.scss"

	/* JS */
	import "@plugins/jquery/dist/jquery.min.js"
	import "@plugins/jquery-validation/jquery-validation.js"
	import "@helper/general_function.js"
	import "@plugins/extension.js"
	import "@frontend/js/main.js"

	/* Vue Component */
	Vue.component("ImagePreview", () => import("@backend/components/ImagePreview.vue"))
	Vue.component("Component404", () => import("@frontend/components/Component404.vue"))
	Vue.component("VInfo", () => import("@frontend/components/VInfo.vue"))

	global.$ = global.jQuery
	global.Gen = Gen
	global.App.user = null

	export default {
		name: "FoMain",
		extends: GlobalVue,
		async mounted() {
			window.history.scrollRestoration = 'manual'
			global.FOGen = FOGen
			/* CSS */
			Gen.loadCss("https://fonts.googleapis.com/css?family=PT+Serif:400,400i,700|Roboto:400,700")
			Gen.loadCss("/frontend/css/bootstrap.css")
			Gen.loadCss("/frontend/css/style.css?v=0.2")
			Gen.loadCss("/frontend/css/dark.css?v=0.5")
			Gen.loadCss("/frontend/css/font-icons.css")
			Gen.loadCss("/frontend/css/animate.css")
			Gen.loadCss("/frontend/css/et-line.css")
			Gen.loadCss("/frontend/css/magnific-popup.css")
			Gen.loadCss("/frontend/css/responsive.css")

			// demos
			Gen.loadCss("/frontend/demos/conference/css/fonts.css?v=2.4")
			Gen.loadCss("/frontend/demos/conference/css/colors.css?v=3.0")
			Gen.loadCss("/frontend/demos/conference/conference.css?v=4.1")

			// animate
			Gen.loadCss("/frontend/css/aos.css")
			Gen.loadCss("/frontend/css/rating.css")
			Gen.loadCss("/frontend/css/components/radio-checkbox.css")
			Gen.loadCss("/frontend/css/custom_l9.css?v=4.1")
			Gen.loadCss("/frontend/css/responsive_l9.css?v=0.7.6")
			Gen.loadCss("/frontend/css/font_style.css")
			Gen.loadCss("/frontend/css/loader.css?v=0.1.4")
			/* Load Variable */
			this.refreshVariableFo()

			/* JS */
			// await Gen.loadScript("/frontend/js/jquery.js")
			await Gen.loadScript("/frontend/js/plugins.js")
			await Gen.loadScript("/frontend/js/aos.js")
			await Gen.loadScript("/frontend/js/functions.js?v=0.4")
			await Gen.loadScript("/frontend/js/custom.js?v=0.2")

			await Gen.loadScript("/frontend/include/rs-plugin/js/jquery.themepunch.tools.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/jquery.themepunch.revolution.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.video.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.slideanims.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.actions.min.js")
			await Gen.loadScript(
				"/frontend/include/rs-plugin/js/extensions/revolution.extension.layeranimation.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.kenburn.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.navigation.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.migration.min.js")
			await Gen.loadScript("/frontend/include/rs-plugin/js/extensions/revolution.extension.parallax.min.js")
			await Gen.loadScript("/backend/assets/node_modules/popper/popper.min.js")

			global.$ = global.jQuery

			/* Ready Loaded */
			$(document).ready(() => {
				this.$root.ready = true
				this.checkModalAge()
			})
			AOS.init();

			var self = this
			$(document).on("click", "a", function (e) {
				if ($(this).is("[reload]")) return;
				if ($(this).is("[target]")) return;
				if ($(this).attr("data-lightbox")) return;
				if ($(this).attr("href") == "javascript:;") return;
				if ($(this).attr("href")[0] == "#") return;
				if ($(this).attr("href").indexOf(location.origin) > -1) {
					e.preventDefault();
					self.$router.push($(this).attr("href").replace(location.origin, ""))
				}
				if ($(this).attr("href").indexOf(":") < 0) {
					e.preventDefault();
					if (self.$route.fullPath != $(this).attr("href")) self.$router.push($(this).attr(
						"href"))
				}
			})
			$(document).on('keydown', '.form-control', function (e) {
				if (e.which === 32 && e.target.selectionStart === 0) {
					return false;
				}
			});

			this.$root.topProgress = this.$refs.topProgress
			if (Gen.getStorage('legal_age') < 17) this.openModalAge()
			this.checkModalCampaign()
			setTimeout(() => {
				$('#footer').css('margin-top', '0')
			}, 2000)
		},
		components: {
			// vueTopprogress
		},
		methods: {
			openModalAge(callback) {
				VModal.openModal("ModalLegalAge", {
					year: '-',
					month: '-'
				}, (resp) => {
					setTimeout(() => { this.checkModalCampaign() }, 200);
				})
			},
			openModalCampaign(data) {
				VModal.openModal("ModalCampaign", { data:data }, {})
			},
			checkModalAge() {
				var dates = Gen.getStorage('time_legal')
				Gen.apirest("/expired-age", { date: dates }, (err, resp) => {
					if (resp.data > 0) this.openModalAge()
				}, 'POST')
			},
			checkModalCampaign() {
				console.log("open campaign")
				if (!Gen.getStorage('legal_age')) return
				let input = {}
				input.url = this.$route.path
				input.last = Gen.getStorage('last_visit')
				Gen.apirest("/check-campaign", input, (err, resp) => {
					if (err) return console.log(err.resp)
					if (!resp) return console.log("no campaign")
					if (Gen.getStorage('campaign' + this.$route.path) == resp.id) return console.log( "campaign already show")
					if (resp.show=='Y') {
						this.openModalCampaign(resp.data)
						Gen.putStorage("last_visit", resp.last)
					}else if(resp.show=='N'){
						console.log("Not this time open campaing")
					}
					console.log("id not found")
				}, 'POST')
			}
		},
		watch: {
			$route() {
				if (typeof $ != 'undefined') $("html,body").scrollTop(0)
				AOS.init();
				if (Gen.getStorage('legal_age') < 21) this.openModalAge()
				setTimeout(() => {
					if (!this.isMobile || !this.isTablet) {
						$('#primary-menu ul ul, #primary-menu ul .mega-menu-content').css('display', 'none');
					}
				}, 500)
				this.checkModalAge()
				this.checkModalCampaign()
			}
		},
	}

	$(document).scroll(function () {
		var y = $(this).scrollTop();
		if (y > 200) {
			$('.bottom_navigation').fadeIn();
		} else {
			$('.bottom_navigation').fadeOut();
		}
	});
</script>

<template>
	<div id="FoMain">
		<router-view />
	</div>
</template>