import Gen from '@helper/Gen';

const FOGen = {
  _static_content:false,
  staticContent(cb){
    if(this._static_content) return cb(this._static_content)
    if(this._static_content===false){
      this._static_content = null
      return Gen.apirest('/static-content', {}, (err, resp) => {
        if(err) return
        this._static_content = resp.data
        cb(resp.data)
      })
    }
    let it = setInterval(()=>{
      if(this._static_content){
        cb(this._static_content)
        clearInterval(it)
      }
    }, 100)
  }
}

export default FOGen